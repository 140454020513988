html,
body {
  height: 100%;
}

body {
  background: #fbfbfd;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}

svg {
  display: block;
}

.control {
  border-color: transparent;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.control-btn {
  border-radius: 1rem;
  padding: 0.15rem 0.5rem;
}

.control-btn-alt {
  border-radius: 1rem;
  border-color: transparent;
  box-shadow: none;
  background-color: rgb(226, 226, 226);
  color: #999;
}

.control-btn:hover {
  background-color: #f9fafb;
}

.control-btn-alt:hover {
  color: #000;
}

.control-field {
  padding: 0.25rem 0.5rem;
}

.control-field:focus-visible {
  border-color: #3c6bc2;
  outline: 1px solid #3c6bc2;
}

.tools {
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(75, 75, 255, 0.5);
}

.modal-active {
  display: flex;
}

.modal_body {
  position: relative;
  background: #fff;
  border-radius: 15px;
  flex-grow: 1;
  max-width: 90%;
}

.modal_close {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 16px;
  width: 25px;
  height: 25px;
}

.modal_content {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(112px, 1fr));
  gap: 4px;
  padding: 8px;
  overflow: auto;
  scrollbar-width: thin;
  max-height: 80vh;
}

.flag {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  flex-basis: 100px;
  cursor: pointer;
}

.flag_emoji {
  pointer-events: none;
  font-size: 56px;
  line-height: 1;
}

.flag_description {
  pointer-events: none;
  font-size: 10px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.picked {
  font-size: 80px;
  line-height: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  cursor: default;
}
